/* Carrusel infinito */
.carrusel-infinito,
#content .carrusel-infinito {
  position: relative;
  /* Controls */
}
.carrusel-infinito .ci-general-wrapper,
#content .carrusel-infinito .ci-general-wrapper {
  overflow: hidden;
  position: relative;
  margin: 0 2.1em;
  padding: 0 0.5em;
}
.carrusel-infinito .ci-general-wrapper .cil,
#content .carrusel-infinito .ci-general-wrapper .cil {
  white-space: nowrap;
  position: relative;
}
.carrusel-infinito .ci-general-wrapper .cil li,
#content .carrusel-infinito .ci-general-wrapper .cil li {
  padding-left: 0;
  margin-bottom: 0;
}
.carrusel-infinito .ci-general-wrapper .cil a:active, .carrusel-infinito .ci-general-wrapper .cil a:focus, .carrusel-infinito .ci-general-wrapper .cil a:hover,
#content .carrusel-infinito .ci-general-wrapper .cil a:active,
#content .carrusel-infinito .ci-general-wrapper .cil a:focus,
#content .carrusel-infinito .ci-general-wrapper .cil a:hover {
  text-decoration: underline;
}
.carrusel-infinito .ci-general-wrapper .cil span.element-invisible,
#content .carrusel-infinito .ci-general-wrapper .cil span.element-invisible {
  display: inline;
}
.carrusel-infinito .ci-controls,
#content .carrusel-infinito .ci-controls {
  margin: 0;
  list-style-type: none;
}
.carrusel-infinito .ci-controls li a,
#content .carrusel-infinito .ci-controls li a {
  position: absolute;
  top: 34%;
  padding: 1.3em 1em;
  background-color: transparent;
}
.carrusel-infinito .ci-controls li.ci-prev a,
#content .carrusel-infinito .ci-controls li.ci-prev a {
  left: 0;
}
.carrusel-infinito .ci-controls li.ci-next a,
#content .carrusel-infinito .ci-controls li.ci-next a {
  right: 0;
}
.carrusel-infinito .ci-controls li a:hover,
.carrusel-infinito .ci-controls li a:active,
.carrusel-infinito .ci-controls li a:focus,
#content .carrusel-infinito .ci-controls li a:hover,
#content .carrusel-infinito .ci-controls li a:active,
#content .carrusel-infinito .ci-controls li a:focus {
  background-color: #007B22;
}
.carrusel-infinito .ci-controls li a span:after,
#content .carrusel-infinito .ci-controls li a span:after {
  content: "";
  width: 0.8em;
  height: 0.8em;
  border-style: solid;
  border-width: 0.2em 0.2em 0 0;
  display: block;
  position: absolute;
  top: 0.8em;
  border-color: black;
  z-index: 1;
}
.carrusel-infinito .ci-controls li.ci-prev a span:after,
#content .carrusel-infinito .ci-controls li.ci-prev a span:after {
  transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  left: 0.8em;
}
.carrusel-infinito .ci-controls li.ci-next a span:after,
#content .carrusel-infinito .ci-controls li.ci-next a span:after {
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  right: 0.8em;
}
.carrusel-infinito .ci-controls li a:hover span:after,
.carrusel-infinito .ci-controls li a:active span:after,
.carrusel-infinito .ci-controls li a:focus span:after,
#content .carrusel-infinito .ci-controls li a:hover span:after,
#content .carrusel-infinito .ci-controls li a:active span:after,
#content .carrusel-infinito .ci-controls li a:focus span:after {
  border-color: white;
}

@media screen and (max-width: 767px) {
  .carrusel-infinito .ci-general-wrapper,
  #content .carrusel-infinito .ci-general-wrapper {
    margin: 0 2em;
  }
  .carrusel-infinito .ci-general-wrapper .cil,
  #content .carrusel-infinito .ci-general-wrapper .cil {
    margin: 0.5em 0;
    min-height: 12.6em;
  }
  .carrusel-infinito .ci-general-wrapper .cil li,
  #content .carrusel-infinito .ci-general-wrapper .cil li {
    margin-right: 0.3em;
  }
}
@media screen and (max-width: 480px) {
  .carrusel-infinito .ci-general-wrapper,
  #content .carrusel-infinito .ci-general-wrapper {
    margin: 0 1.1rem;
  }
  .carrusel-infinito .ci-general-wrapper .cil,
  #content .carrusel-infinito .ci-general-wrapper .cil {
    min-height: 10.2em;
  }
  .carrusel-infinito .ci-general-wrapper .cil li,
  #content .carrusel-infinito .ci-general-wrapper .cil li {
    margin-right: 0.1em;
  }
  .carrusel-infinito .carrusel-infinito-controls,
  #content .carrusel-infinito .carrusel-infinito-controls {
    position: relative;
  }
  .carrusel-infinito .carrusel-infinito-controls .ci-controls,
  #content .carrusel-infinito .carrusel-infinito-controls .ci-controls {
    margin: 0 0 0.5em 0;
    height: 2.6em;
    width: auto;
  }
  .carrusel-infinito .carrusel-infinito-controls .ci-controls li a,
  #content .carrusel-infinito .carrusel-infinito-controls .ci-controls li a {
    top: 0;
  }
  .carrusel-infinito .carrusel-infinito-controls .ci-controls li.ci-prev a,
  #content .carrusel-infinito .carrusel-infinito-controls .ci-controls li.ci-prev a {
    left: 35%;
  }
  .carrusel-infinito .carrusel-infinito-controls .ci-controls li.ci-next a,
  #content .carrusel-infinito .carrusel-infinito-controls .ci-controls li.ci-next a {
    right: 35%;
  }
}
/* ------------------------------------------ */
/* Estilos para el producto Enlaces con Texto */
/* ------------------------------------------ */
#enlacesConTextoViewlet {
  background-color: #eaeaea;
}

#carrusel-infinito-enlacescontexto {
  padding: 1.5em 0;
}
.carousel-markers-on #carrusel-infinito-enlacescontexto {
  padding-top: 3.5em;
}

#carrusel-infinito-enlacescontexto ul {
  text-align: center;
  padding: 0;
  margin: 0;
}

#carrusel-infinito-enlacescontexto ul li {
  display: inline-block;
  text-align: center;
  width: 10.5em;
  vertical-align: top;
  white-space: normal;
}

#carrusel-infinito-enlacescontexto ul li img {
  width: 5em;
}

#carrusel-infinito-enlacescontexto ul li span.enlaceConTextoItem-title {
  color: black;
  line-height: 1em;
  margin: 1em 0 0;
  font-weight: bold;
  display: block;
  letter-spacing: normal;
}

#carrusel-infinito-enlacescontexto ul li span {
  display: block;
  padding: 0 1em;
  line-height: normal;
}

#carrusel-infinito-enlacescontexto ul li a {
  display: block;
  color: #333;
  padding: 0.5em 0 0.7em 0;
}
#carrusel-infinito-enlacescontexto ul li a:hover, #carrusel-infinito-enlacescontexto ul li a:active, #carrusel-infinito-enlacescontexto ul li a:focus {
  text-decoration: none;
}
#carrusel-infinito-enlacescontexto ul li a:hover span.enlaceConTextoItem-title, #carrusel-infinito-enlacescontexto ul li a:active span.enlaceConTextoItem-title, #carrusel-infinito-enlacescontexto ul li a:focus span.enlaceConTextoItem-title {
  color: white;
  text-decoration: none;
}

/* -------------------------------------- */
/* ------------ Media Queries ----------- */
/* -------------------------------------- */
@media screen and (max-width: 1024px) {
  #carrusel-infinito-enlacescontexto ul li {
    margin: 0 1.2em 1em 1.2em;
  }
}
@media screen and (max-width: 1000px) {
  #carrusel-infinito-enlacescontexto ul li {
    margin: 0 1em 1em 1em;
  }
}
@media screen and (max-width: 860px) {
  #carrusel-infinito-enlacescontexto ul li {
    margin: 0 0.5em 1em 0.5em;
  }
}

